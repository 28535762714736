<template>
  <UiModalContainer
    footer-classes="flex justify-end gap-3"
    :modal-show="showModal"
    :modal-width="50"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span>Print Fee Invoice</span>
    </template>
    <div id="invoiceBody">
      <div class="flex flex-wrap mt-5 print:px-5">
        <div class="font-roboto sm:text-2xl text-xl w-1/2 order-1 print:order-1 print:ml-0">
          {{ generalSettingInstituteName }}
        </div>
        <div v-if="generalSettingLogoURL" class="flex w-1/2 order-2 print:order-2 justify-end">
          <img
            class="md:ml-auto print:ml-auto w-16 h-16 rounded-full"
            :src="generalSettingLogoURL"
            :alt="generalSettingInstituteName"
          />
        </div>
        <div class="w-full order-3 mb-2 mt-3 print:mt-2 print:order-3">
          <div class="flex md:text-lg text-base mt-5 sm:mt-0 w-full">
            <div class="min-w-1.5 mr-2 font-extrabold">Campus:</div>
            <div class="text-menu capitalize">
              {{ currentCampusScope.title }}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="flex flex-wrap print:px-5 font-roboto text-xs md:text-base">
        <div class="w-full sm:flex-row flex-col sm:gap-0 gap-5 flex mt-5">
          <div class="flex sm:w-2/4 w-full">
            <div class="min-w-1.5 mr-2 font-bold">First Name:</div>
            <div class="text-menu capitalize">
              {{
                (feeInvoice.student_fee.student && feeInvoice.student_fee.student.first_name) ||
                GENERAL_CONSTANTS.NOT_APPLICABLE
              }}
            </div>
          </div>
          <div class="flex sm:w-2/4 w-full">
            <div class="min-w-1.5 mr-2 font-bold">Last Name:</div>
            <div class="text-menu">
              {{
                (feeInvoice.student_fee.student && feeInvoice.student_fee.student.last_name) ||
                GENERAL_CONSTANTS.NOT_APPLICABLE
              }}
            </div>
          </div>
        </div>
        <div class="w-full sm:flex-row flex-col sm:gap-0 gap-5 flex mt-5">
          <div class="flex sm:w-2/4 w-full">
            <div class="min-w-1.5 mr-2 font-bold">Roll No:</div>
            <div class="text-menu">
              {{
                (feeInvoice.student_fee.student.users_roles_entities[0] &&
                  feeInvoice.student_fee.student.users_roles_entities[0].roll_no) ||
                GENERAL_CONSTANTS.NOT_APPLICABLE
              }}
            </div>
          </div>
          <div class="flex sm:w-2/4 w-full">
            <div class="min-w-1.5 mr-2 font-bold">Registeration No:</div>
            <div class="text-menu">
              {{
                (feeInvoice.student_fee.student.student_info &&
                  feeInvoice.student_fee.student.student_info.registration_no) ||
                GENERAL_CONSTANTS.NOT_APPLICABLE
              }}
            </div>
          </div>
        </div>
        <div class="w-full sm:flex-row flex-col sm:gap-0 gap-5 flex mt-5">
          <div class="flex sm:w-2/4 w-full">
            <div class="min-w-1.5 mr-2 font-bold">Class:</div>
            <div class="text-menu capitalize">
              {{
                (feeInvoice.student_fee.class && feeInvoice.student_fee.class.title) ||
                GENERAL_CONSTANTS.NOT_APPLICABLE
              }}
            </div>
          </div>
          <div class="flex sm:w-2/4 w-full">
            <div class="min-w-1.5 mr-2 font-bold">Section:</div>
            <div class="text-menu">
              {{
                (feeInvoice.student_fee.section && feeInvoice.student_fee.section.title) ||
                GENERAL_CONSTANTS.NOT_APPLICABLE
              }}
            </div>
          </div>
        </div>
        <div class="w-full sm:flex-row flex-col sm:gap-0 gap-5 flex mt-5">
          <div class="flex sm:w-2/4 w-full">
            <div class="min-w-1.5 mr-2 font-bold">Fee Title:</div>
            <div class="text-menu capitalize">
              {{
                feeInvoice.student_fee.section_fee
                  ? feeInvoice.student_fee.section_fee.title
                  : feeInvoice.student_fee.title
              }}
            </div>
          </div>
          <div class="flex sm:w-2/4 w-full">
            <div class="min-w-1.5 mr-2 font-bold">Fee Type:</div>
            <div class="text-menu">
              {{
                (feeInvoice.student_fee.fee_type && feeInvoice.student_fee.fee_type.title) ||
                GENERAL_CONSTANTS.NOT_APPLICABLE
              }}
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-5" />
      <div class="print:mx-5 print:min-w-7">
        <div
          class="rounded-lg border border-primary-grey items-center mt-6 lg:max-w-screen-2xl md:max-w-screen-sm sm:max-w-xl custom-max-w overflow-auto mb-10 print:max-w-screen-md print:min-w-7"
        >
          <table class="w-full table-auto whitespace-nowrap border-primary-grey">
            <thead class="bg-bright-gray">
              <tr class="text-left">
                <th
                  v-for="(head, index) in tableHeaders"
                  :key="index"
                  class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pl-0 rtl:pr-4 ltr:pl-4 ltr:pr-0"
                >
                  <span
                    class="ltr:border-r rtl:border-l border-primary-grey block rtl:pl-3 ltr:pr-3"
                  >
                    {{ head }}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="border-t border-primary-grey">
                <td
                  class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4 relative cursor-pointer text-sm"
                >
                  <span>
                    {{
                      (feeInvoice && feeInvoice.student_fee.fee_amount) ||
                      GENERAL_CONSTANTS.AMOUNT_ZERO
                    }}
                  </span>
                </td>
                <td
                  class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 relative cursor-pointer text-sm"
                >
                  <span>
                    {{ (feeInvoice && feeInvoice.paid_amount) || GENERAL_CONSTANTS.AMOUNT_ZERO }}
                  </span>
                </td>
                <td
                  class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 relative cursor-pointer text-sm"
                >
                  <span>
                    {{ (feeInvoice && feeInvoice.payable_amount) || GENERAL_CONSTANTS.AMOUNT_ZERO }}
                  </span>
                </td>
                <td
                  class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 relative cursor-pointer text-sm"
                >
                  <span>
                    {{ $filters.formattedDate(feeInvoice.paid_date) }}
                  </span>
                </td>
                <td
                  class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 relative cursor-pointer text-sm"
                >
                  <span>
                    {{ feeInvoice.received_by && feeInvoice.received_by.first_name }}
                    {{ feeInvoice.received_by && feeInvoice.received_by.last_name }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <UIButton button="primary" @click="printHandler()">
        <template v-if="isLoading">
          <Loader></Loader>
        </template>
        <template v-else>
          <span>Print</span>
        </template>
      </UIButton>
    </template>
  </UiModalContainer>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import GENERAL_CONSTANTS from '@/src/constants/general-constants'
import { mapState } from 'vuex'
import { printDocument } from '@utils/generalUtil'
import UIButton from '@src/components/UiElements/UIButton.vue'

export default {
  name: 'DeleteExamComponent',
  components: {
    UiModalContainer,
    UIButton,
  },
  data() {
    return {
      GENERAL_CONSTANTS,
      tableHeaders: ['Total Fee', 'Fee Received', 'Pending Fee', 'Received Date', 'Received By'],
    }
  },
  computed: {
    ...mapState('fee', ['showModal', 'feeInvoice']),
    ...mapState('layout', ['currentCampusScope']),
    ...mapState('settings', ['generalSettingLogoURL', 'generalSettingInstituteName']),
  },
  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$store.commit('fee/SET_SHOW_MODAL', false)
          this.$store.commit('fee/SET_FEE_INVOICE', null)
          break
      }
    },
    printHandler() {
      printDocument('invoiceBody')
      this.$store.commit('fee/SET_SHOW_MODAL', false)
      this.$store.commit('fee/SET_FEE_INVOICE', null)
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-w-h {
  min-width: 34px;
  max-width: 34px;
  min-height: 34px;
  max-height: 34px;
}
@media (min-width: 524px) and(max-width: 639px) {
  .custom-max-w {
    max-width: 465px;
  }
}
@media (min-width: 425px) and(max-width: 510px) {
  .custom-max-w {
    width: 360px;
  }
}
@media (min-width: 374px) and(max-width: 424px) {
  .custom-max-w {
    width: 305px;
  }
}
@media (min-width: 350px) and(max-width: 374px) {
  .custom-max-w {
    width: 289px;
  }
}
@media (min-width: 320px) and(max-width: 349px) {
  .custom-max-w {
    width: 267px;
  }
}
</style>
