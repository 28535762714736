<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="ACCEPT_PAYMENT"
      tooltip-title="ACCEPT_PAYMENT"
      :display-breadcrumb="true"
    />
    <div
      class="w-full flex md:flex-row flex-col md:justify-between justify-start md:items-center items-start mt-2"
    >
      <div class="font-medium font-roboto md:text-base text-sm text-label-text">
        Select Student:
      </div>
      <div class="w-[250px]">
        <UiSingleSelect
          v-model="selectedStudent"
          label="full_name"
          :hide-title="true"
          :options="studentsList"
          class="w-full mx-0 mt-1 md:mt-0 md:-mb-5 mb-0"
          :select-object-without-reduce-key="true"
          :search-through-api="true"
          @search="getStudentList"
          @change="updateStudent(selectedStudent.id)"
          @deSelect="updateStudent()"
        />
      </div>
    </div>
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else :key="selectedStudent" class="mt-2 relative">
      <UserDetailedView
        :is-not-open-in-modal="true"
        :user="userDetails"
        :default-value="GENERAL_CONSTANTS.BLANKED"
      />
      <div
        class="flex md:flex-row flex-col md:justify-between justify-start md:items-center items-start mb-2"
      >
        <div class="font-medium md:text-base text-sm">Select Fee:</div>
        <div class="w-[250px]">
          <UiSingleSelect
            v-model="selectedFee"
            label="title"
            :hide-title="true"
            :options="pendingFeeList"
            class="mx-0 md:px-0 mt-1 md:mt-0 md:-mb-5 mb-0"
          />
        </div>
      </div>
      <div
        class="bg-primary-white p-3 border border-primary-grey-200 rounded-lg text-xs md:text-base"
      >
        <div class="flex gap-3 mt-3 flex-col md:flex-row">
          <div class="flex flex-1">
            <div class="font-bold min-w-1 md:w-2/4">
              <span>Fee Title</span>
              <span>:</span>
            </div>
            <div class="text-primary-grey-light capitalize md:w-3/4">
              {{ (selectedFee && selectedFee.title) || GENERAL_CONSTANTS.BLANKED }}
            </div>
          </div>
          <div class="flex flex-1">
            <div class="font-bold min-w-1 md:w-2/4">
              <span>Due Date</span>
              <span>:</span>
            </div>
            <div class="text-primary-grey-light capitalize md:w-3/4">
              <span v-if="selectedFee">
                {{ $filters.formattedDate(selectedFee.due_date) }}
              </span>
              <span v-else>
                {{ GENERAL_CONSTANTS.BLANKED }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex gap-3 mt-3 flex-col md:flex-row">
          <div class="flex flex-1">
            <div class="font-bold min-w-1 md:w-2/4">
              <span>Total Fee</span>
              <span>:</span>
            </div>
            <div class="text-primary-grey-light capitalize md:w-3/4">
              {{ (selectedFee && selectedFee.fee_amount) || GENERAL_CONSTANTS.BLANKED }}
            </div>
          </div>
          <div class="flex flex-1">
            <div class="font-bold min-w-1 md:w-2/4">
              <span>Paid Amount</span>
              <span>:</span>
            </div>
            <div class="text-primary-grey-light capitalize md:w-3/4">
              <span v-if="selectedFee">{{ selectedFee.paid_amount }}</span>
              <span v-else>{{ GENERAL_CONSTANTS.BLANKED }}</span>
            </div>
          </div>
        </div>
        <div class="flex gap-3 mt-3 flex-col md:flex-row">
          <div class="flex flex-1">
            <div class="font-bold min-w-1 md:w-2/4">
              <span>Fee Type</span>
              <span>:</span>
            </div>
            <div class="text-primary-grey-light capitalize md:w-3/4">
              {{ (selectedFee && selectedFee.fee_type.title) || GENERAL_CONSTANTS.BLANKED }}
            </div>
          </div>
          <div class="flex flex-1">
            <div class="font-bold min-w-1 md:w-2/4">
              <span>Payable Amount</span>
              <span>:</span>
            </div>
            <div class="text-primary-grey-light capitalize md:w-3/4">
              {{ (selectedFee && selectedFee.payable_amount) || GENERAL_CONSTANTS.BLANKED }}
            </div>
          </div>
        </div>
        <div class="flex gap-3 mt-3 flex-col md:flex-row">
          <div class="flex flex-1">
            <div class="font-bold min-w-1 md:w-2/4">
              <span>Discount</span>
              <span>:</span>
            </div>
            <div class="text-primary-grey-light capitalize md:w-3/4">
              <span v-if="selectedFee">{{ selectedFee.discount_amount }}</span>
              <span v-else>{{ GENERAL_CONSTANTS.BLANKED }}</span>
            </div>
          </div>
          <div class="flex flex-1">
            <div class="font-bold min-w-1 md:w-2/4">
              <span>Fine Amount</span>
              <span>:</span>
            </div>
            <div class="text-primary-grey-light capitalize md:w-3/4">
              <span v-if="selectedFee">{{ selectedFee.fine_amount }}</span>
              <span v-else>{{ GENERAL_CONSTANTS.BLANKED }}</span>
            </div>
          </div>
        </div>
        <div v-if="!currentSectionScope" class="flex gap-3 mt-3 flex-col md:flex-row">
          <div class="flex flex-1">
            <div class="font-bold min-w-1 md:w-2/4">
              <span>Class</span>
              <span>:</span>
            </div>
            <div class="text-primary-grey-light capitalize md:w-3/4">
              <span v-if="selectedFee">{{ selectedFee.section.class.title }}</span>
              <span v-else>{{ GENERAL_CONSTANTS.BLANKED }}</span>
            </div>
          </div>
          <div class="flex flex-1">
            <div class="font-bold min-w-1 md:w-2/4">
              <span>Section</span>
              <span>:</span>
            </div>
            <div class="text-primary-grey-light capitalize md:w-3/4">
              <span v-if="selectedFee">{{ selectedFee.section.title }}</span>
              <span v-else>{{ GENERAL_CONSTANTS.BLANKED }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 flex gap-2 items-center md:text-base text-sm">
        <div>Other pending fee than selected fee :</div>
        <div>
          {{
            pendingFeeMeta && selectedFee
              ? pendingFeeMeta.total_payable_amount - selectedFee.payable_amount
              : GENERAL_CONSTANTS.BLANKED
          }}
        </div>
      </div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <div class="md:py-2 py-1">
          <div class="font-medium font-roboto md:text-base text-sm text-label-text mt-3">
            Payment Details:
          </div>
          <div>
            <InputFieldWrapper class="md:mt-6 mt-3">
              <InputBox
                v-model="paymentDetails.paid_amount"
                type="text"
                name="Receiving Amount"
                title="Receiving Amount"
                label="Receiving Amount"
                :disabled="!!acceptedFee"
                placeholder="Receiving Amount"
                class="flex-1"
                rules="required|numeric"
              />
              <UiDatePicker
                :key="forceRerender"
                v-model="paymentDetails.paid_date"
                title="RECEIVED_ON"
                :disabled="!!acceptedFee"
                :date-mode="DATE_MODE.END_DATE_TIME"
                class="flex-1"
                rules="required"
              />
            </InputFieldWrapper>
            <InputFieldWrapper class="">
              <InputBox
                v-model="paymentDetails.payment_method"
                type="text"
                :disabled="!!acceptedFee"
                name="Payment Method"
                title="Payment Method"
                label="Payment Method"
                placeholder="Payment Method"
                class="flex-1"
              />
              <InputBox
                v-model="paymentDetails.payment_comment"
                type="text"
                name="PAYMENT_COMMENT"
                title="PAYMENT_COMMENT"
                :disabled="!!acceptedFee"
                label="PAYMENT_COMMENT"
                placeholder="PAYMENT_COMMENT"
                class="flex-1"
              />
            </InputFieldWrapper>
          </div>
        </div>
        <Divider />
        <div class="flex justify-end gap-5">
          <button
            v-if="!acceptedFee"
            class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
            :disabled="!selectedStudent || !selectedFee"
            type="button"
            @click.prevent="handleSubmit(acceptPayment)"
          >
            <span>Accept</span>
          </button>
          <div v-else class="flex gap-5">
            <button
              class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 rtl:ml-3 flex justify-center items-center primary-button-outline"
              @click.prevent="printInvoice"
            >
              Print
            </button>
            <button
              class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 rtl:ml-3 flex justify-center items-center primary-button-outline"
              @click.prevent="moveToNextFee"
            >
              Next
            </button>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <PrintPaidInvoice v-if="feeInvoice" />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import isEmpty from 'lodash/isEmpty'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import { dropdownTopBottom, objectDeepCopy } from '@utils/generalUtil.js'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UserDetailedView from '@src/components/userDetailedView.vue'
import Divider from '@src/components/Divider.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import { Form as ValidationObserver } from 'vee-validate'
import { DATE_MODE } from '@/src/constants/date-time-constants.js'
import PrintPaidInvoice from '@/src/components/fee/PrintPaidInvoice.vue'
import { formatDate } from '@utils/moment.util'
import { buildWhereQuery } from '@/src/utils/filters'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    TitleWrapper,
    Loader,
    UiSingleSelect,
    UserDetailedView,
    Divider,
    InputBox,
    UiDatePicker,
    ValidationObserver,
    PrintPaidInvoice,
    InputFieldWrapper,
  },
  data() {
    return {
      isEmpty,
      DATE_MODE,
      GENERAL_CONSTANTS,
      studentsList: [],
      selectedStudent: null,
      selectedFee: null,
      pendingFeeList: [],
      pendingFeeMeta: {},
      paymentDetails: {
        paid_amount: '',
        payment_method: '',
        payment_comment: '',
        student_fee_id: '',
        paid_date: '',
      },
      acceptedFee: null,
      forceRerender: 0,
    }
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
      isLoading: (state) => state.layout.isLoading,
      feeInvoice: (state) => state.fee.feeInvoice,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
    }),
    userDetails() {
      return { user_details: this.selectedStudent }
    },
  },
  watch: {
    currentSectionScope: {
      handler(val) {
        this.selectedStudent = null
        this.resetDataToDefault()
        this.getStudentList()
      },
    },
    currentCampusScope: {
      handler(val) {
        if (val) {
          this.resetDataToDefault()
          this.selectedStudent = null
          this.getStudentList()
        }
      },
    },
  },
  created() {
    this.setRightbarData()
  },
  mounted() {
    this.getStudentList()
  },
  methods: {
    dropdownTopBottom,

    getStudentList(query = '') {
      let payload = {
        search: query,
      }
      this.getPendingFeeStudentsList(payload).then((response) => {
        this.studentsList = response?.data?.records
      })
    },
    updateStudent(id) {
      this.resetDataToDefault()
      if (id) this.getStudentFee(id)
    },
    getStudentFee(id) {
      let payload = { $where: { ...buildWhereQuery(FILTER_KEYS.IN, 'student_ids', [id]) } }

      this.getStudentsReceivableFees(payload).then((response) => {
        this.pendingFeeList = objectDeepCopy(response?.data?.records)
        this.pendingFeeMeta = response?.data?.meta

        this.pendingFeeList.forEach((fee) => {
          fee['title'] = fee?.section_fee?.title || fee?.title || GENERAL_CONSTANTS.NOT_APPLICABLE
          fee['detail'] = `Created On : ${formatDate(fee.inserted_at)} `
        })
        this.getSingleStudentFeeStats(payload).then((statsResponse) => {
          this.setRightbarData(statsResponse.data)
        })
      })
    },
    setRightbarData(stats = {}) {
      const rightBarContent = {
        header: {
          title: 'ACCEPT_PAYMENT',
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: 'TOTAL_FEE_AMOUNT',
                value: stats?.total_fee_amount || GENERAL_CONSTANTS.EMPTY_RECORD,
                tooltipDescription: 'Total fee',
              },
              {
                text: 'TOTAL_PENDING_FEE',
                value: stats?.total_pending_amount || GENERAL_CONSTANTS.EMPTY_RECORD,
                tooltipDescription: 'Total pending fee',
              },
              {
                text: 'TOTAL_RECEIVED_FEE',
                value: stats?.total_received_amount || GENERAL_CONSTANTS.EMPTY_RECORD,
                tooltipDescription: 'Total received fee',
              },
              {
                text: 'TOTAL_DISCOUNT_AMOUNT',
                value: stats?.total_discount_amount || GENERAL_CONSTANTS.EMPTY_RECORD,
                tooltipDescription: 'Total discount amount',
              },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    resetDataToDefault() {
      this.paymentDetails.paid_amount = ''
      this.pendingFeeList = []
      this.selectedFee = null
      this.acceptedFee = null
      this.paymentDetails.payment_method = ''
      this.paymentDetails.payment_comment = ''
      this.paymentDetails.student_fee_id = ''
      this.paymentDetails.paid_date = ''
      this.forceRerender++
      window.scrollTo(0, 0)
      this.setRightbarData()
    },
    moveToNextFee() {
      this.selectedStudent = null
      this.resetDataToDefault()
      this.setRightbarData()
    },
    acceptPayment() {
      this.paymentDetails.student_fee_id = this.selectedFee.id
      this.paymentDetails.paid_amount = +this.paymentDetails.paid_amount
      this.acceptStudentFee(this.paymentDetails).then((res) => {
        this.acceptedFee = res?.data
        const feeType = this.selectedFee?.fee_type?.title
        const payeeName = this.selectedFee?.student?.first_name
        const amount = this.paymentDetails.paid_amount
        this.setRightbarData()
        this.$store.commit('toast/NEW', {
          type: 'success',
          message: `${feeType} ${this.$t('toast.of')} ${payeeName} ${this.$t(
            'toast.for',
          )} ${amount} ${this.$t('toast.is received successfully')}`,
        })
      })
    },
    printInvoice() {
      this.$store.commit('fee/SET_SHOW_MODAL', true)
      this.$store.commit('fee/SET_FEE_INVOICE', this.acceptedFee)
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('fee', [
      'getPendingFeeStudentsList',
      'getStudentsReceivableFees',
      'acceptStudentFee',
      'getSingleStudentFeeStats',
    ]),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
.paid {
  padding: 4px 12px;
  color: #267c26;
  background-color: #e9f2e9;
  border-radius: 3px;
}
.delay {
  padding: 4px 12px;
  color: #ffc201;
  background-color: #fff9e6;
  border-radius: 3px;
}
.due {
  padding: 4px 12px;
  color: #ea3535;
  background-color: #fce1e1;
  border-radius: 3px;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
.tableDropDown {
  top: 32px;
  right: -21px;
  &::before {
    position: absolute;
    top: -7px;
    right: 30px;
    width: 12px;
    height: 12px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid var(--primary-grey);
    border-left: 1px solid var(--primary-grey);
    transform: rotate(45deg);
  }
}
.info {
  top: 35px;
  border: 1px solid var(--text-color-info);
  &::before {
    position: absolute;
    top: -7px;
    right: 30px;
    width: 12px;
    height: 12px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid var(--text-color-info);
    border-left: 1px solid var(--text-color-info);
    transform: rotate(45deg);
  }
}
</style>
